import { UntypedFormControl } from '@angular/forms';
import { isArray } from 'lodash';

export const EMAIL_VALIDATION_REGEX =
  /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,8})$/;

export class EmailValidator {
  public static emailValidator(c: UntypedFormControl) {
    if (!c.value) {
      return null;
    }

    const isValid = EmailValidator.validate(c.value);

    if (isValid) {
      return null;
    } else {
      return {
        emailvalidator: {
          valid: false,
        },
      };
    }
  }

  public static emailArrayValidator(c: UntypedFormControl) {
    if (!c.value || !isArray(c.value)) {
      return null;
    }

    const invalidEmail = c.value.find(
      (email) => !EmailValidator.validate(email),
    );

    if (!invalidEmail) {
      return null;
    } else {
      return {
        emailArrayValidator: {
          valid: false,
        },
      };
    }
  }

  public static validate(email: string): boolean {
    return EMAIL_VALIDATION_REGEX.test(email);
  }
}
