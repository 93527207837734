import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { EmailTemplateService } from '../../services/email-template.service';

import * as featureActions from './actions';

@Injectable()
export class EmailTemplatePreviewEffects {
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(featureActions.loadRequest),
      switchMap(
        ({
          payload,
          lang_iso_code,
          template_email_type,
          template_email_id,
          expected_payments,
          onSuccess,
        }) =>
          this.dataService
            .load(
              payload,
              lang_iso_code,
              template_email_type,
              template_email_id,
              expected_payments,
            )
            .pipe(
              map((response: IResponseSuccess) => {
                if (onSuccess) {
                  onSuccess(response.data[0]);
                }

                return featureActions.loadSuccess({
                  preview: response.data[0],
                });
              }),
              catchError((error) => {
                this.errorHandler.handle(error);
                return of(featureActions.loadFailure({ error }));
              }),
            ),
      ),
    ),
  );

  constructor(
    private dataService: EmailTemplateService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}
}
