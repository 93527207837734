import { createAction, props } from '@ngrx/store';

import { CreateReservationRequest } from '../../models/requests/create-reservation-request';
import { AutomaticPayment, AutomaticPaymentSummary } from '../../models';

export const loadRequest = createAction(
  '[Email Template Preview] Load Request',
  props<{
    payload: CreateReservationRequest;
    lang_iso_code: string;
    template_email_type: 'system' | 'custom';
    template_email_id?: number;
    expected_payments?: AutomaticPaymentSummary['expected_payments'];
    onSuccess?: (preview: { body: string; subject?: string }) => void;
  }>(),
);

export const loadSuccess = createAction(
  '[Email Template Preview] Load Success',
  props<{ preview: { body: string; subject?: string } }>(),
);

export const loadFailure = createAction(
  '[Email Template Preview] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Email Template Preview] Reset State');
